.introjs-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.introjs-helperLayer {
  border-radius: 0px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  left: -13px;
}

div.introjs-helperLayer {
  border-radius: 0px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  left: -13px;
}

.introjs-tooltip {
  color: #000;
  width: 561px;
  max-width: 561px !important;
  max-height: 386px;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  border-radius: 16px;
}

.introjs-button:focus {
  box-shadow: none;
  border: none;
}

.introjs-tooltip-header {
  font-family: Nunito;
  padding-top: 22px;
}

.introjs-helperNumberLayer {
  padding: 0px;
  font-family: Nunito;
}

.introjs-arrow.left {
  left: -9px;
}

.introjs-tooltip-title {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
}

.introjs-tooltiptext {
  font-size: 16px;
  font-family: Nunito;
  padding: 20px;
  line-height: 1.5;
  color: #000;
}

.introjs-button.introjs-prevbutton {
  background-color: transparent;
  border: 2px solid #b03b88;
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--Secondary-Main, #9d0a6b);

  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.introjs-button.introjs-nextbutton {
  background-color: #b03b88;
  color: #fff;
  border-radius: 4px;
  padding: 10px 16px;
  text-shadow: none;
  text-transform: uppercase;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  border: none;
}

.introjs-tooltipbuttons {
  border: none;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
  padding: 10px 30px 18px 30px;
  align-items: center;
}
.introjs-arrow {
  color: #007bff;
  font-size: 24px;
}

.btn-new {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.div-button {
  width: 100%;
}

.button-dontShow {
  display: flex;
  background-color: transparent;
  color: #b03b88;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
